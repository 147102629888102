import * as yaml from 'yaml';

interface Condition {
  id: string;
  value: string | number | { value: string | number; seq?: number };
  operator: string;
  type: string;
}

interface Rule {
  condition?: string;
  rules?: Rule[];
  anchor_name?: string;
}

interface Anchor {
  condition: string;
  description: string;
}

interface JsonRules {
  assetType?: string;
  anchors: Record<string, Anchor>;
  rules: any[];
}

// Operator mappings
const operatorMappingInverted: Record<string, string> = {
  eq: '==',
  lte: '<=',
  lt: '<',
  gt: '>',
  gte: '>=',
  neq: '!=',
  is: 'is',
};

const arithOperatorMapping: Record<string, string> = {
  "+": 'add',
  "-": 'sub',
  "*": 'mul',
  "/": 'div',
};

const operatorMapping: Record<string, string> = {
  "==": 'eq',
  "=": 'eq',
  "!=": 'neq',
  "<": 'lt',
  "<=": 'lte',
  ">": 'gt',
  ">=": 'gte',
  "is": 'eq'
};

const buildAnchors = (newYaml: yaml.Document, anchors: Record<string, any>) => {
  for (const [key, value] of Object.entries(anchors)) {
    const regex = /^(\S+)\.(\S+)\s*([=!<>is]+)\s*(.*)$/;
    const match = value.condition?.match(regex);

    if (match) {
      const [, stateName, condName, operator, rawVal] = match;

      let val: any = rawVal;
      let seq = 0;

      if (val.startsWith('"') && val.endsWith('"')) {
        val = val.slice(1, -1); // Remove quotes
      }
      if (val === 'None') {
        val = 'none';
      }

      // Handle numeric values with decimals (like 2.5 -> 2_pt_5)
      const regexDec = /^(\d+)\.(\d+)$/;
      const matchDec = val.match(regexDec);
      if (matchDec) {
        val = `${matchDec[1]}_pt_${matchDec[2]}`;
      }

      let anchorName = '';
      if (stateName === '_sys_attr') {
        anchorName = `system_${condName}_${operator}_${val}`;
      } else if (stateName === '_state') {
        if (condName === 'in_business_hour' && val === '2') {
          val = 'none';
        }
        anchorName = `status_${condName}_${operator}_${val}`;
      } else {
        if (seq !== 0) {
          anchorName = `${stateName}_${seq}_${condName}_${operator}_${val}`;
        } else {
          anchorName = `${stateName}_${condName}_${operator}_${val}`;
        }
      }

      // Find operator from operatorMappingInverted
      const op = Object.keys(operatorMappingInverted).find(
        (k) => operatorMappingInverted[k] === operator
      );

      let cond: string | undefined;
      if (value.condition.type === 'double' || value.condition.type === 'integer') {
        cond = `${stateName}.${condName} ${op} ${val}`;
      } else if (value.condition.type === 'string') {
        if (val === 'none') {
          cond = `${stateName}.${condName} is None`;
        } else if (isNaN(Number(val))) {
          cond = `${stateName}.${condName} ${op} "${val}"`;
        } else {
          cond = `${stateName}.${condName} ${op} ${Number(val)}`;
        }
      }

      const anch = newYaml.createNode({
        condition: value.condition,
        description: value.description,
      });
      anch.anchor = key;

      newYaml.set(key, anch);
    } else {
      console.log("Anchor NO MATCH: ", value)
    }
  }
};

const addCondNode = (cond: string): string | null => {
  // Regex to capture the pattern "<field> <operator> <value>"
  const regex = /^(\S+)\.(\S+)\s*([=!<>is]+)\s*(.+)$/;
  const match = cond.match(regex);
  let stateName: string | undefined;
  let condName: string | undefined;
  let anchorName: string | undefined;
  let seq = 0;

  if (match) {
    const operator = match[3]; // =, ==, !=, <=, <, >=, >
    let val = match[4]; // 0 (or any other value)
    if (val.startsWith('"') && val.endsWith('"')) {
      val = val.slice(1, -1); // Remove quotes
    }
    if (val === 'None') {
      val = 'none'
    }

    stateName = match[1];
    condName = match[2];
    let op = operatorMapping[operator];
    if (typeof val === 'string' && val.startsWith('$')) {
      const parts = val.replace(/^\$/, '').trim().split(' ');
      val = `${parts[0]}`;
      if (parts.length ===3) {
        let op = arithOperatorMapping[parts[1]];
        val = `${parts[0]}_${op}_${parts[2]}`
      }
    } else {
      const regexDec = /^(\d+)\.(\d+)$/;
      const matchDec = val.toString().match(regexDec);
      if (matchDec) {
        val = `${matchDec[1]}_pt_${matchDec[2]}`;
      }
    }

    if (stateName === '_sys_attr') {
      anchorName = `system_${condName}_${op}_${val}`;
    } else if (stateName === '_state') {
      if (condName === 'in_business_hour') {
        if (val === "2") {
          val = 'none';
        }
      }
      anchorName = `status_${condName}_${op}_${val}`;
    } else {
      if (seq !== 0 && seq !== undefined) {
        anchorName = `${stateName}_${seq}_${condName}_${op}_${val}`;
      } else {
        anchorName = `${stateName}_${condName}_${op}_${val}`;
      }
    }

    return anchorName;
  } else {
    console.log("NO MATCH: ", cond)
  }

  return null;
};

const buildYamlNode = (
  newYaml: yaml.Document,
  rule: Rule,
  topLevel = true
): yaml.Node | null => {
  if (rule.condition) {
    if (rule.condition === 'AND') {
      const andNode = newYaml.createNode({ AND: [] });
      rule.rules?.forEach((subRule) => {
        (andNode as any).items[0].value.items.push(buildYamlNode(newYaml, subRule, false));
      });
      
      if (topLevel) {
        return andNode;
      } else {
        var condNode = newYaml.createNode({ condition: { }});
        condNode.set('condition', andNode);
        return condNode;
      }
    } else if (rule.condition === 'OR') {
      const orNode = newYaml.createNode({ OR: [] });
      rule.rules?.forEach((subRule) => {
        (orNode as any).items[0].value.items.push(buildYamlNode(newYaml, subRule, false));
      });
      
      if (topLevel) {
        return orNode;
      } else {
        var condNode = newYaml.createNode({ condition: { }});
        condNode.set('condition', orNode);
        return condNode;
      }
    }
  } else {
    // console.log("rule: ", rule)
    const anchor_name = addCondNode(rule)?.trim()
    if (anchor_name) {
      if (!newYaml.get(anchor_name)) {
        const anch = newYaml.createNode({
          condition: rule.trim(),
          description: rule.replace('.', ' - ').trim(),
        });
        anch.anchor = anchor_name;
        newYaml.set(anchor_name, anch);
      }
      const alias = new yaml.Alias(anchor_name);
      return newYaml.createNode(alias);
    } else {
      return newYaml.createNode({ condition: addCondNode(rule) });
    }
  }
  return null;
};

const jsonToYaml = (jsonRules: JsonRules): yaml.Document => {
  try {
    const newYaml = new yaml.Document(new yaml.YAMLMap());
    buildAnchors(newYaml, jsonRules.anchors);

    if (jsonRules.assetType) {
      newYaml.add(newYaml.createPair('asset_type', jsonRules.assetType));
    }

    const yamlRulesList = jsonRules.rules.map((jsonRule) => {
      const yamlRule: Record<string, any> = {
        code: jsonRule.code,
        description: jsonRule.description,
        status: jsonRule.status,
        deploy: jsonRule.deploy || "enable",
        summary: jsonRule.summary,
      };

      if (jsonRule.prerequisite) {
        yamlRule['prerequisite'] = buildYamlNode(newYaml, jsonRule.prerequisite, true);
      }

      if (jsonRule.trigger_pre) {
        yamlRule['trigger_pre'] = buildYamlNode(newYaml, jsonRule.trigger_pre, true);
      }

      if (jsonRule.trigger_post) {
        yamlRule['trigger_post'] = buildYamlNode(newYaml, jsonRule.trigger_post, true);
      }

      if (jsonRule.email_condition) {
        yamlRule['email_condition'] = buildYamlNode(newYaml, jsonRule.email_condition, true);
      }

      return yamlRule;
    });

    newYaml.set('_rules', newYaml.createNode({ Default: yamlRulesList }));

    return newYaml;
  } catch (e) {
    console.error('jsonToYaml ERROR -', e);
    throw e;
  }
};

export default jsonToYaml;
