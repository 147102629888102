import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { parseDocument } from "yaml";
import {
  FieldSelectorProps,
  QueryBuilder,
  Rule,
  RuleGroupType,
  RuleType,
  ValueEditorProps,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import yamlToJsonNew, {
  convertToQueryBuilderFormat,
  formatRules,
} from "./yamlParser";
import saveAssetRules from "./saveAssetRules.ts";
import Utility from "../../../helpers/Utility";

interface Rule {
  code: string;
  status: string;
  deploy: string;
  description: string;
  summary: string;
  prerequisite?: any;
  trigger_pre?: any;
  trigger_post?: any;
  email_condition?: any;
  link?: string;
}

interface RulesData {
  anchors: any[];
  rules: Rule[];
}

const operators = [
  { name: "=", label: "=" },
  { name: "!=", label: "!=" },
  { name: "<", label: "<" },
  { name: ">", label: ">" },
  { name: "<=", label: "<=" },
  { name: ">=", label: ">=" },
];

const defaultPostConditionFields = [
  { name: "_state.consecutive_count", label: "Consecutive Count" },
  { name: "_state.stay_mins", label: "Condition Stay(in mins)" },
];
const postConditionOperators = [
  { name: ">", label: ">" },
  { name: ">=", label: ">=" },
];

const defaultPrerequisiteFields = [
  { name: "_sys_attr.rv_mode", label: "RV Mode" },
  { name: "_sys_attr.heat_provided_by", label: "Heat Provided By" },
  { name: "_sys_attr.refrigerant", label: "Refrigerant" },
  { name: "_sys_attr.r_switch_condition", label: "R Switch Condition" },
];
const prerequisiteOperators = [{ name: "=", label: "=" }];

const defaultEmailFields = [
  { name: "_state.in_business_hour", label: "In Business Hour" },
  {
    name: "_state.email_elapsed_mins",
    label: "Wait (in mins) before sending next Email",
  },
];
const emailOperators = [
  { name: "=", label: "=" },
  { name: ">", label: ">" },
  { name: ">=", label: ">=" },
];

const RuleGenerator = () => {
  const initialQuery: RuleGroupType = { combinator: "and", rules: [] };
  const [rules, setRules] = useState<any>([]);
  const [showRuleJson, setShowRuleJson] = useState<RuleGroupType>(initialQuery);
  const [assetRules, setAssetRules] = useState<RulesData>([]);
  const [error, setError] = useState(null);
  const [assetType, setAssetType] = useState();
  const [assetTypeForRules, setAssetTypeForRules] = useState([]);
  const [prerequisites, setPrerequisites] =
    useState<RuleGroupType>(initialQuery);
  const [conditions, setConditions] = useState<RuleGroupType>(initialQuery);

  const [postConditions, setPostConditions] =
    useState<RuleGroupType>(initialQuery);
  const [defaultConditions, setDefaultConditions] = useState<any>([]);

  const [email, setEmail] = useState<RuleGroupType>(initialQuery);
  const [code, setCode] = useState("");
  const [status, setStatus] = useState("");
  const [deployStatus, setDeployStatus] = useState("");
  const [description, setDescription] = useState("");
  const [summary, setSummary] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ruleJsonModalData, setRuleJsonModalData] = useState<any>(null);

  const handleChange = (event: any) => {
    if (!event.target.value) return;
    setAssetType(event.target.value);
    getComponentsType(assetTypeForRules, event.target.value);
    fetchRules(event.target.value);
  };

  const fetchRules = async (assetType: any) => {
    if (!assetType) return;
    const tokenStr = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/rules/${assetType}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const yamlText = await response.text();
      const rules = parseDocument(yamlText);
      // console.log("rules: ", rules)
      const jsonRules = yamlToJsonNew(assetType, rules);
      // console.log("jsonRules NEW: ", jsonRules)
      setAssetRules(jsonRules);
      setRules(jsonRules.rules);
    } catch (err: any) {
      console.log(err);
      setError(err.message);
    }
  };

  const getAssetType = async () => {
    const tokenStr = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/asset_types`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Asset Type data: ", data);
      const transformedArray: any = Object.keys(data).map((key, index) => ({
        id: index + 1,
        name: key,
        display_name: data[key].display_name,
        components: data[key].components || [],
      }));
      setAssetTypeForRules(transformedArray);
      setAssetType(transformedArray[0].name);
      fetchRules(transformedArray[0].name);
      await getComponentsType(transformedArray, transformedArray[0].name);
      return transformedArray;
    } catch (err) {
      console.error("Error in getAssetType:", err);
      return [];
    }
  };

  const getComponentsType = async (transformedArray: any, assetType: string) => {
    let selected_asset_type: any = transformedArray[0];
    for (let index = 0; index < transformedArray.length; index++) {
      const item: any = transformedArray[index];
      if (item.name == assetType) {
        selected_asset_type = item;
        break;
      }
    }

    var selectedAssetTypeMap: any = {};
    for (var i = 0; i < selected_asset_type.components.length; i++) {
      selectedAssetTypeMap[selected_asset_type.components[i].name] =
        selected_asset_type.components[i].count;
    }

    const tokenStr = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/component_types`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const componentData = await response.json();
      console.log("Asset Type componentData: ", componentData);

      var components = [];
      for (var i = 0; i < componentData.length; i++) {
        var component = componentData[i];
        if (component.name in selectedAssetTypeMap) {
          component.count = selectedAssetTypeMap[component.name];
          components.push(component);
        }
      }

      let defaultConditionFields = [];
      for (var i = 0; i < components.length; i++) {
        var component = components[i];
        var sensors = component.sensors;
        var name = component.name;
        for (var j = 0; j < sensors.length; j++) {
          // TODO: Ensure name does not contains '-' within
          var n = name + "." + sensors[j].name;
          var l = name + " - " + sensors[j].name;
          defaultConditionFields.push({
            name: n,
            count: component.count,
            label: l,
          });
        }
      }

      setDefaultConditions(defaultConditionFields);
      return defaultConditionFields;
    } catch (err) {
      console.error("Error in getAssetType:", err);
      return [];
    }
  };

  useEffect(() => {
    getAssetType();
  }, []);

  const handleShowRules = () => {
    Utility.showModal("showRuleJSON");
  };

  const onUpdateRuledata = async (item: any) => {
    setIsModalOpen(!isModalOpen);
    setCode(item?.code || "");
    setStatus(item?.status || "");
    setDeployStatus(item?.deploy || "");
    setDescription(item?.description || "");
    setSummary(item?.summary || "-");
    setPrerequisites(initialQuery);
    setConditions(initialQuery);
    setPostConditions(initialQuery);
    setEmail(initialQuery);

    // Post Condition
    if (item?.trigger_post) {
      setPostConditions(convertToQueryBuilderFormat(item?.trigger_post));
    }

    // Condition
    if (item?.trigger_pre) {
      setConditions(convertToQueryBuilderFormat(item?.trigger_pre));
    }

    // Prerequisite
    if (item?.prerequisite) {
      setPrerequisites(convertToQueryBuilderFormat(item?.prerequisite));
    }

    // Email Condition
    if (item?.email_condition) {
      setEmail(convertToQueryBuilderFormat(item?.email_condition));
    }
  };

  const getFieldsFromItem = (conditions: any, defaultFields: any[]) => {
    const fieldsSet = new Set<string>();

    (conditions?.rules ?? []).forEach((condition: any) => {
      if (typeof condition?.field === "string") {
        const field = condition.field;
        if (field) fieldsSet.add(field);
      }
    });

    const extractedFields = Array.from(fieldsSet).map((field) => {
      let val = {
        name: field,
        label: field.replace(".", " - "),
      };

      for (let index = 0; index < defaultFields.length; index++) {
        const item: any = defaultFields[index];
        if (item.name == field) {
          val.label = item.label;
          break;
        }
      }

      return val;
    });

    // Combine default and extracted fields, removing duplicates
    const allFieldsMap = new Map(
      [...defaultFields, ...extractedFields].map((field) => [field.name, field])
    );

    return Array.from(allFieldsMap.values());
  };

  const postConditionFields = getFieldsFromItem(
    postConditions ?? {},
    defaultPostConditionFields
  );
  const conditionFields = getFieldsFromItem(
    conditions ?? {},
    defaultConditions
  );
  const prerequisiteFields = getFieldsFromItem(
    prerequisites ?? {},
    defaultPrerequisiteFields
  );
  const emailFields = getFieldsFromItem(email ?? {}, defaultEmailFields);

  const CustomValueEditor: ValueEditorProps['component'] = (props) => {
    const { value, handleOnChange, operator, field, rule } = props;
    const [type, setType] = useState<'value' | 'field'>(() =>
      typeof value === 'string' && value.startsWith('$') ? 'field' : 'value'
    );
    const [arithmeticOperator, setArithmeticOperator] = useState<string>('');
    const [selectedField, setSelectedField] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>(value || '');
  
    useEffect(() => {
      if (typeof value === 'string' && value.startsWith('$')) {
        const parts = value.replace(/^\$/, '').split(' ');
  
        setSelectedField(parts[0] || '');
        setArithmeticOperator(parts[1] || '');
        setInputValue(parts[2] || '');
        setType('field');
      } else {
        setInputValue(value);
        setType('value');
      }
    }, [value]);
  
    const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newType = e.target.value as 'value' | 'field';
      setType(newType);
      if (newType === 'value') {
        handleOnChange(inputValue);
      } else if (newType === 'field') {
        handleOnChange(`$${selectedField} ${arithmeticOperator} ${inputValue}`);
      }
    };
  
    const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newField = e.target.value;
      setSelectedField(newField);
      handleOnChange(`$${newField} ${arithmeticOperator} ${inputValue}`);
    };
  
    const handleArithmeticOperatorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newOperator = e.target.value;
      setArithmeticOperator(newOperator);
      handleOnChange(`$${selectedField} ${newOperator} ${inputValue}`);
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      if (type === 'value') {
        handleOnChange(newValue);
      } else {
        handleOnChange(`$${selectedField} ${arithmeticOperator} ${newValue}`);
      }
    };
  
    return (
      <div style={{ display: 'flex', gap: '5px'}}>
        <select style={{width: 85}} value={type} onChange={handleTypeChange}>
          <option value="value">Value</option>
          <option value="field">Field</option>
        </select>
  
        {type === 'field' && (
          <>
            {/* Field Dropdown */}
            <select style={{width: 160}} value={selectedField} onChange={handleFieldChange}>
              <option value="">Select Field</option>
              {conditionFields.map((f) => (
              <option key={f.name} value={f.name}>
                {f.label}
              </option>
            ))}
          </select>
  
            {/* Arithmetic Operator Dropdown */}
            <select style={{width: 112}} value={arithmeticOperator} onChange={handleArithmeticOperatorChange}>
              <option value="">Select Operator</option>
              <option value="+">+</option>
              <option value="-">-</option>
              <option value="*">*</option>
              <option value="/">/</option>
            </select>
          </>
        )}
  
        {/* Input Field */}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          style={{ flex: 1, border: 'none', width: 75}}
        />
      </div>
    );
  };
  
  // const CustomFieldSelector = (props: FieldSelectorProps) => {
  //   const { handleOnChange, value, options, rule } = props;
  //   const [sequence, setSequence] = useState(rule?.sequence || "1");
  
  //   const handleSequenceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //     const newSequence = e.target.value;
  //     setSequence(newSequence);
      
  //     // Update the rule directly (works in v4.5.3)
  //     if (rule) {
  //       rule.sequence = newSequence;
  //     }
  //   };
  
  //   return (
  //     <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  //       <select
  //         value={value}
  //         onChange={(e) => handleOnChange(e.target.value)}
  //       >
  //         {options.map((field) => (
  //           <option key={field.name} value={field.name}>
  //             {field.label}
  //           </option>
  //         ))}
  //       </select>

  //       <select
  //         value={sequence}
  //         onChange={handleSequenceChange}
  //       >
  //         {Array.from({ length: options.length }, (_, i) => i + 1).map((num) => (
  //           <option key={num} value={num.toString()}>
  //             {num}
  //           </option>
  //         ))}
  //       </select>
  //     </div>
  //   );
  // };

  
  const CustomFieldEditor: React.FC<FieldSelectorProps> = (props) => {
    const { value, options, handleOnChange, title, className, rule } = props;
    
    // Parse the field name without sequence info for dropdown display
    const parseFieldName = (fieldWithSeq: string | undefined) => {
      if (!fieldWithSeq) return '';
      return fieldWithSeq.includes(':seq=') ? fieldWithSeq.split(':seq=')[0] : fieldWithSeq;
    };
    
    const parseSequence = (fieldWithSeq: string | undefined) => {
      if (!fieldWithSeq || !fieldWithSeq.includes(':seq=')) return '1';
      return fieldWithSeq.split(':seq=')[1];
    };
    
    const [fieldValue, setFieldValue] = useState<string>(parseFieldName(value));
    const [sequenceValue, setSequenceValue] = useState<string>(parseSequence(value));
    
    useEffect(() => {
      if (value !== undefined) {
        setFieldValue(parseFieldName(value));
        setSequenceValue(parseSequence(value));
      }
    }, [value]);
    
    const getCurrentField = () => {
      return options.find(opt => opt.name === fieldValue);
    };
    
    const shouldShowSequence = () => {
      const fieldObj = getCurrentField();
      return fieldObj && fieldObj.count !== undefined && fieldObj.count > 1;
    };
    
    // Generate sequence options based on field count
    const getSequenceOptions = () => {
      const fieldObj = getCurrentField();
      if (!fieldObj || fieldObj.count === undefined || fieldObj.count <= 1) {
        return ['1'];
      }
      
      return Array.from({ length: fieldObj.count }, (_, i) => String(i + 1));
    };
    
    const handleFieldChange = (newFieldValue: string) => {
      setFieldValue(newFieldValue);
      
      // Get the field object to check if we should show sequence
      const fieldObj = options.find(opt => opt.name === newFieldValue);
      if (!fieldObj) return;
      
      const newSequenceValue = '0';
      setSequenceValue(newSequenceValue);
      
      if (fieldObj.count !== undefined && fieldObj.count > 1) {
        const combinedValue = `${newFieldValue}:seq=${newSequenceValue}`;
        handleOnChange(combinedValue);
      } else {
        handleOnChange(newFieldValue);
      }
    };
    
    const handleSequenceChange = (newSequenceValue: string) => {
      setSequenceValue(newSequenceValue);
      
      if (!fieldValue || !shouldShowSequence()) return;
      
      const combinedValue = `${fieldValue}:seq=${newSequenceValue}`;      
      handleOnChange(combinedValue);
    };

    const sequenceOptions = getSequenceOptions();

    
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {/* Field dropdown from options */}
        <select
          value={fieldValue}
          onChange={(e) => handleFieldChange(e.target.value)}
          className={className}
          title={title}
        >
          {options.map((option) => (
            <option key={option.name} value={option.name}>
              {option.label}
            </option>
          ))}
        </select>
        
        {/* Sequence dropdown */}
        {shouldShowSequence() && (
          <select
            value={sequenceValue}
            onChange={(e) => handleSequenceChange(e.target.value)}
            className="form-select text-sm"
            style={{ width: '60px' }}
          >
            <option value="0">0</option>
            {/* {Array.from({ length: options.count }, (_, i) => i + 1).map((num) => (
              <option key={num} value={num.toString()}>
                {num}
              </option>
            ))} */}
            {sequenceOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  };

  
  const [processedQuery, setProcessedQuery] = useState<any>(null);
  const [ruleHistory, setRuleHistory] = useState<Record<string, any>>({});

  const handleQueryChange = (query: RuleGroupType) => {
    const newRuleHistory = { ...ruleHistory };
    
    // Process the query and track rule state
    const processRules = (rules: any[]) => {
      return rules.map(rule => {
        // If this is a rule group, process its rules
        if (rule.rules) {
          const processedGroup = {
            ...rule,
            rules: processRules(rule.rules)
          };
          return processedGroup;
        }
        
        const ruleId = rule.id;
        if (ruleId && newRuleHistory[ruleId]) {
          const prevRule = newRuleHistory[ruleId];
          
          // If field changed but includes sequence, preserve operator and value
          if (rule.field && rule.field.includes(':seq=') && 
              prevRule.field && rule.field.split(':seq=')[0] === prevRule.field.split(':seq=')[0]) {
          }
        }
        
        // Update history with current rule state
        if (ruleId) {
          newRuleHistory[ruleId] = { ...rule };
        }
        
        return rule;
      });
    };
    
    processRules(query.rules);
    setRuleHistory(newRuleHistory);
    setConditions(query);
    
    // Process query to extract sequences for display
    // const processed = processQueryWithSequences(query);
    // setProcessedQuery(processed);
  }

  // Function to process the query and extract sequence information
  const processQueryWithSequences = (query: RuleGroupType) => {
    // Deep clone the query to avoid modifying the original
    const processedQuery = JSON.parse(JSON.stringify(query));
    
    // Process rules recursively
    const processRules = (rules: any[]) => {
      return rules.map(rule => {
        // If this is a rule group, process its rules
        if (rule.rules) {
          return {
            ...rule,
            rules: processRules(rule.rules)
          };
        }
        
        // If this is a rule with field containing sequence info
        if (rule.field && typeof rule.field === 'string' && rule.field.includes(':seq=')) {
          const [fieldName, seqPart] = rule.field.split(':seq=');
          return {
            ...rule,
            originalField: rule.field,
            field: fieldName,
            sequence: seqPart
          };
        }
        
        return rule;
      });
    };
    
    processedQuery.rules = processRules(processedQuery.rules);
    return processedQuery;
  }
  
  async function saveJsonRule() {
    let rule: any = {
      code: code,
      status: status,
      deploy: deployStatus || "enable",
      description: description,
      summary: summary,
      prerequisite: prerequisites,
      trigger_pre: conditions,
      trigger_post: postConditions,
      email_condition: email,
    };

    if (rule.prerequisite && rule.prerequisite.rules.length > 0) {
      // checkAndAddAnchorsRule(assetRules.anchors, rule.prerequisite);
      const result = formatRules(rule.prerequisite);
      rule.prerequisite = result;
    } else {
      delete rule.prerequisite;
    }

    if (rule.trigger_pre && rule.trigger_pre.rules.length > 0) {
      // checkAndAddAnchorsRule(assetRules.anchors, rule.trigger_pre);
      const result = formatRules(rule.trigger_pre);
      rule.trigger_pre = result;
    } else {
      delete rule.trigger_pre;
    }

    if (rule.trigger_post && rule.trigger_post.rules.length > 0) {
      // checkAndAddAnchorsRule(assetRules.anchors, rule.trigger_post);
      const result = formatRules(rule.trigger_post);
      rule.trigger_post = result;
    } else {
      delete rule.trigger_post;
    }

    if (rule.email_condition && rule.email_condition.rules.length > 0) {
      // checkAndAddAnchorsRule(assetRules.anchors, rule.email_condition);
      const result = formatRules(rule.email_condition);
      rule.email_condition = result;
    } else {
      delete rule.email_condition;
    }

    let found = false;
    for (let i = 0; i < assetRules.rules.length; i++) {
      if (assetRules.rules[i].code === rule.code) {
        const link = assetRules.rules[i].link;
        assetRules.rules[i] = rule;
        assetRules.rules[i].link = link;
        found = true;
        break;
      }
    }

    if (!found) {
      assetRules.rules.push(rule);
    }
    setRuleJsonModalData(rule);
    Utility.showModal("ruleJSON");
    return assetRules;
  }

  async function saveRulesToFile() {
    const assetTypeRules = await saveAssetRules(assetRules);
    const tokenStr = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/rules/${assetType}`,
        {
          method: "POST",
          body: assetTypeRules.toString(),
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "application/yaml; charset=utf-8",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.text();
      Utility.showModal("saveRulesModal");
    } catch (err) {
      console.log("Error Asset Post: ", err);
    }
  }

  function addRuleModal() {
    setIsModalOpen(!isModalOpen);

    setCode("");
    setStatus("");
    setStatus("enable");
    setDescription("");
    setSummary("");
    setPrerequisites(initialQuery);
    setConditions(initialQuery);
    setPostConditions(initialQuery);
    setEmail(initialQuery);
  }

  function onDeleteRule(item: any, index: number): void {
    try {
      const updatedRules = [...rules];
      updatedRules.splice(index, 1);

      setRules(updatedRules);
      setAssetRules((prev) => ({
        ...prev,
        rules: updatedRules,
      }));
    } catch (err) {
      console.log("ERROR On Delete Rule: ", err);
    }
  }

  return (
    <div>
      {/* Header */}
      <div
        className="bs-docs-header"
        id="content"
        style={{ background: "linear-gradient(135deg, #0074d9, #001f3f)" }}
      >
        <div className="container">
          <h1>Mechasense Rule Generator</h1>
        </div>
      </div>

      <div className="container bs-docs-container">
        <div className="row-rg">
          {/* Asset Type Selection */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="asset_type" className="form-label">
              Asset Type
            </label>
            <div style={{ padding: "0.2rem 1.25rem" }}>
              <select
                className="form-select"
                id="asset_type"
                aria-label="Select asset type"
                onChange={handleChange}
              >
                {assetTypeForRules?.map((item: any, index: any) => (
                  <option key={index} value={item.name}>
                    {item.display_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Rules Section */}
          <div className="col-md-9" role="main">
            <section className="bs-docs-section clearfix">
              <h1 id="basic" className="page-header">
                Rules
              </h1>

              <div className="col-md-12">
                <button
                  id=""
                  className="btn btn-primary"
                  onClick={async () => {
                    await saveRulesToFile();
                  }}
                >
                  Save Rules to File
                </button>
              </div>

              <div className="col-md-12">
                <button
                  id="add-rule"
                  type="button"
                  className="btn btn-primary"
                  onClick={addRuleModal}
                >
                  Add Rule
                </button>
              </div>

              {/* Rule Modal */}
              {isModalOpen && (
                <div className="card card-body">
                  <div className="mb-3">
                    <label htmlFor="rule_name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="rule_name"
                      value={code}
                      placeholder="Example rule"
                      onChange={(e: any) => setCode(e.target.value)}
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                    {/* Status Dropdown */}
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                      <label
                        htmlFor="rule_status"
                        className="form-label"
                        style={{ marginRight: "10px" }}
                      >
                        Status
                      </label>
                      <select
                        className="form-select"
                        id="rule_status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option>Select an option</option>
                        <option value="green">Green</option>
                        <option value="yellow">Yellow</option>
                        <option value="orange">Orange</option>
                        <option value="red">Red</option>
                        <option value="darkred">Dark Red</option>
                        <option value="purple">Purple</option>
                      </select>
                    </div>

                    {/* Deploy Status Dropdown */}
                    <div style={{ display: "flex", flexDirection: "column", width: "50%"  }}>
                      <label
                        htmlFor="deploy_status"
                        className="form-label"
                        style={{ marginRight: "10px" }}
                      >
                        Deploy Status
                      </label>
                      <select
                        className="form-select"
                        id="deploy_status"
                        value={deployStatus}
                        onChange={(e) => setDeployStatus(e.target.value)}
                      >
                        <option value="enable">Enable</option>
                        <option value="disable">Disable</option>
                        <option value="internal">Internal</option>
                      </select>
                    </div>
                  </div>
                  <p></p>
                  <p></p>
                  <div className="mb-3">
                    <label htmlFor="rule_description" className="form-label">
                      Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="rule_description"
                      placeholder="Describes an example rule."
                      value={description}
                      onChange={(e: any) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="rule_summary" className="form-label">
                      Summary
                    </label>
                    <textarea
                      style={{ height: 100 }}
                      className="form-control"
                      id="rule_summary"
                      placeholder="Provide a summary to understand the rule easily."
                      value={summary}
                      onChange={(e: any) => setSummary(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="col-md-12">
                    <h3>Prerequisites</h3>
                    <QueryBuilder
                      fields={prerequisiteFields}
                      query={prerequisites}
                      operators={prerequisiteOperators}
                      onQueryChange={(query: RuleGroupType) =>
                        setPrerequisites(query)
                      }
                    />

                    <div className="btn-group">
                      <button
                        className="btn btn-warning"
                        data-target="prerequisites"
                        onClick={() => setPrerequisites(initialQuery)}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-info"
                        data-target="prerequisites"
                      >
                        Example rule
                      </button>
                      <button
                        className="btn btn-primary"
                        data-target="prerequisites"
                        onClick={() => {
                          setShowRuleJson(prerequisites);
                          handleShowRules();
                        }}
                      >
                        Show rule
                      </button>
                    </div>
                  </div>
                  <p></p>
                  <div className="col-md-12">
                    <h3>Conditions</h3>
                    <QueryBuilder
                      fields={conditionFields}
                      query={conditions}
                      operators={operators}
                      controlElements={{
                        fieldSelector: CustomFieldEditor,
                        valueEditor: CustomValueEditor,
                      }}
                      onQueryChange={handleQueryChange}
                      autoSelectField={false}
                      resetOnFieldChange={false}
                      resetOnOperatorChange={false}
                    />

                    <div className="btn-group">
                      <button
                        className="btn btn-warning"
                        data-target="conditions"
                        onClick={() => setConditions(initialQuery)}
                      >
                        Reset
                      </button>
                      <button className="btn btn-info" data-target="conditions">
                        Example rule
                      </button>
                      <button
                        className="btn btn-primary"
                        data-target="conditions"
                        onClick={() => {
                          console.log("conditions: ", conditions);
                          setShowRuleJson(conditions);
                          handleShowRules();
                        }}
                      >
                        Show rule
                      </button>
                    </div>
                  </div>
                  <p></p>
                  <div className="col-md-12">
                    <h3>Post Conditions</h3>
                    {/* <div id="builder-post_conditions"></div> */}
                    <QueryBuilder
                      fields={postConditionFields}
                      query={postConditions}
                      operators={postConditionOperators}
                      onQueryChange={setPostConditions}
                    />

                    <div className="btn-group">
                      <button
                        className="btn btn-warning"
                        data-target="post_conditions"
                        onClick={() => setPostConditions(initialQuery)}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-info"
                        data-target="post_conditions"
                      >
                        Example rule
                      </button>
                      <button
                        className="btn btn-primary"
                        data-target="post_conditions"
                        onClick={() => {
                          setShowRuleJson(postConditions);
                          handleShowRules();
                        }}
                      >
                        Show rule
                      </button>
                    </div>
                  </div>
                  <p></p>
                  <div className="col-md-12">
                    <h3>Email Conditions</h3>
                    {/* <div id="builder-email_conditions"></div> */}
                    <QueryBuilder
                      fields={emailFields}
                      query={email}
                      operators={emailOperators}
                      onQueryChange={(query: any) => setEmail(query)}
                    />
                    <div className="btn-group">
                      <button
                        className="btn btn-warning"
                        data-target="email_conditions"
                        onClick={() => setEmail(initialQuery)}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-success"
                        data-target="email_conditions"
                      >
                        Example rule
                      </button>
                      <button
                        className="btn btn-primary"
                        data-target="email_conditions"
                        onClick={() => {
                          setShowRuleJson(email);
                          handleShowRules();
                        }}
                      >
                        Show rule
                      </button>
                    </div>
                  </div>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <div className="col-md-12">
                    <div id="builder-save">
                      <button
                        className="btn btn-info"
                        type="button"
                        data-toggle="collapse"
                        data-target="#rule-modal"
                        aria-expanded="false"
                        aria-controls="rule-modal"
                        onClick={async () => await saveJsonRule()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Rules Table */}
              <div className="container">
                <table className="table table-striped border-success">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Status (Color)</th>
                      <th>Deploy Status</th>
                      <th>Description</th>
                      <th>Summary</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rules?.map((item: any, index: number) => (
                      <>
                        {item?.description && (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <td
                              className="rules-code"
                              onClick={() => onUpdateRuledata(item)}
                            >
                              {item?.code}
                            </td>
                            <td>{item?.status}</td>
                            <td>{item?.deploy || 'enable'}</td>
                            <td>{item?.description}</td>
                            <td>{item?.summary ? item?.summary : "-"}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => onDeleteRule(item, index)}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="showRuleJSON"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <span
                aria-hidden="true"
                className="close close-modal"
                aria-label="Close"
                onClick={() => {
                  Utility.closeModal("showRuleJSON");
                }}
                style={{ cursor: "pointer" }}
              >
                &times;
              </span>
            </div>
            <div className="modal-body modal-text">
              <div
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                {showRuleJson ? (
                  <pre className="whitespace-pre-wrap text-sm">
                    {JSON.stringify(showRuleJson, null, 2)}
                  </pre>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  Utility.closeModal("showRuleJSON");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bd-example-modal-lg"
        id="ruleJSON"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <span
                aria-hidden="true"
                className="close close-modal"
                aria-label="Close"
                onClick={() => {
                  Utility.closeModal("ruleJSON");
                  setIsModalOpen(!isModalOpen);
                }}
                style={{ cursor: "pointer" }}
              >
                &times;
              </span>
            </div>
            <div className="modal-body modal-text">
              <div
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                {ruleJsonModalData ? (
                  <pre className="whitespace-pre-wrap text-sm">
                    {JSON.stringify(ruleJsonModalData, null, 2)}
                  </pre>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  Utility.closeModal("ruleJSON");
                  setIsModalOpen(!isModalOpen);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="saveRulesModal"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Save Rules
              </h5>
              <span
                aria-hidden="true"
                className="close close-modal"
                aria-label="Close"
                onClick={() => {
                  Utility.closeModal("saveRulesModal");
                }}
                style={{ cursor: "pointer" }}
              >
                &times;
              </span>
            </div>
            <div className="modal-body modal-text">
              <p>Rules Saved to File</p>
            </div>
            <div className="modal-footer d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  Utility.closeModal("saveRulesModal");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleGenerator;
