import React from 'react'
import {NavLink} from 'react-router-dom';
import Routes from '../../Routes'
import authStore from '../../../store/authStore';
import '../../settings/settings.css';

function SideBar(){
    return (
    <div className="col-2">
        <div className="settings-sidebar">
            <ul className="list-unstyled">
                { authStore?.currentUser?.access?.includes(1) &&
                <li className="border border-inset">
                    <NavLink to ={Routes.systemconfig}>System Configurations</NavLink>
                </li>
                }
                { authStore?.currentUser?.access?.includes(1) &&
                <li className="border border-inset">
                    <NavLink to ={Routes.pinconfig} >Pin Configurations</NavLink>
                </li>
                }
                { authStore?.currentUser?.access?.includes(1) &&
                <li className="border border-inset">
                    <NavLink to ={Routes.organization} >Organizations</NavLink>
                </li>
                }
                {
                <li className="border border-inset">
                    <NavLink to ={Routes.role} >Roles</NavLink>
                </li>
                } 
                {authStore?.currentUser?.access?.includes(1) &&
                <li className="border border-inset">
                    <NavLink to ={Routes.algorithmconfig} >Large Chiller Alerts</NavLink>
                </li>
                }
                {authStore?.currentUser?.access?.includes(1) &&
                <li className="border border-inset">
                    <NavLink to ={Routes.oldRulegenerator} >Old - Rule Generator</NavLink>
                </li>
                }		
                {authStore?.currentUser?.access?.includes(1) &&
                <li className="border border-inset">
                    <NavLink to ={Routes.rulegenerator} >Rule Generator</NavLink>
                </li>
                }		
            </ul>
        </div>
    </div>
    )
}

export default SideBar



