import React from "react";
import { Menu, Layout } from "antd";
import { Link, NavLink, withRouter , RouteComponentProps } from "react-router-dom";

import Icon from "../../components_new/icon";
import Routes from "../../routing/routes";
import authStore from '../../store/authStore';
import constant from '../../config/constants';

import logo from "../../assets/images/logo.png";
import logoSmall from "../../assets/images/logoSmall.png";

import Permissions from '../../config/permssions'


const {
  super_admin_access,
  org_admin_access ,
  view_roles,
  create_edit_delete_roles,
} = Permissions

export interface SidebarProps extends RouteComponentProps {
  collapsed: boolean;
}

const { SubMenu } = Menu;

const Sidebar = (props: SidebarProps) => {

  function isActive(path : string) {      
    return props.location.pathname.match(path);
  };

  function isAccessible(permissions: Array<number>) {
    return permissions.some(p => authStore?.currentUser?.access?.includes(p))
  }

  return (
    <Layout.Sider
      className="left-menu"
      trigger={null}
      collapsible
      collapsed={props.collapsed}
    >
      <div className="logo">
        <Link to={""}><img className="logo-with-text" src={logo} alt="logo" /></Link>
        <Link to={""}><img className="logo-without-text" src={logoSmall} alt="logo" /></Link>
      </div>
      <Menu mode="inline" selectedKeys={['']} defaultSelectedKeys={['1']}>
        { authStore.currentUser && isAccessible(constant.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission)) &&
        <Menu.Item key="1"  className={`${isActive(Routes.dashboard) ? "ant-menu-item-selected" : ""}`} >
          <div className="collapse-menu-item">
            <NavLink to={Routes.dashboard}>
              <Icon className="icon-14 mr-2" iconRef="#dashboard_side" />
              <span className="menu-item">Dashboard</span>
            </NavLink>
          </div>
        </Menu.Item>
        }


        {authStore.currentUser && isAccessible(constant.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission)) &&
        <Menu.Item key="2"  className={`${isActive(Routes.systems) ? "ant-menu-item-selected" : ""}`} >
          <div className="collapse-menu-item">
            <NavLink to={Routes.systems}>
              <Icon className="icon-14 mr-2" iconRef="#systems_side" />
              <span className="menu-item">Details</span>
            </NavLink>
          </div>
        </Menu.Item>
        }

        {authStore.currentUser && isAccessible(constant.Permissions['System Status'].map((p: any) => p.permission)) &&
        <Menu.Item key="6"  className={`${isActive(Routes.devicestatus) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink  to={Routes.devicestatus}>
              <Icon className="icon-14 mr-2" iconRef="#devicestatus_side" />
              <span className="menu-item">System Status</span>
            </NavLink>
          </div>
        </Menu.Item>
        }

        {authStore.currentUser && isAccessible(constant.Permissions['Issue Tracker'].map((p: any) => p.permission)) &&
        <Menu.Item key="15"  className={`${isActive(Routes.issuetracker) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink  to={Routes.issuetracker}>
              <Icon className="icon-14 mr-2" iconRef="#issuetracker_side" />
              <span className="menu-item">Issue Tracker</span>
            </NavLink>
          </div>
        </Menu.Item>
        }

        {authStore.currentUser && isAccessible(constant.Permissions['Issue Tracker'].map((p: any) => p.permission)) &&
        <Menu.Item key="20"  className={`${isActive(Routes.reportGenerator) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink  to={Routes.reportGenerator}>
              <Icon className="icon-14 mr-2" iconRef="#reports-icon" />
              <span className="menu-item">Report Generator</span>
            </NavLink>
          </div>
        </Menu.Item>
        }

        {authStore.currentUser && isAccessible(constant.Permissions['Users'].map((p: any) => p.permission)) &&
        <Menu.Item key="7"  className={`${isActive(Routes.users) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink  to={Routes.users}>
              <Icon className="icon-14 mr-2" iconRef="#users" />
              <span className="menu-item">Users</span>
            </NavLink>
          </div>
        </Menu.Item>
        }

        {authStore.currentUser && isAccessible(constant.Permissions['Field Inspection'].map((p: any) => p.permission)) &&
        <Menu.Item key="5"  className={`${isActive(Routes.inspectionchecklist) || isActive(Routes.inspectionform) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink to={Routes.inspectionchecklist}>
              <Icon className="icon-14 mr-2" iconRef="#users_icon" />
              <span className="menu-item">Field Inspection</span>
            </NavLink>
          </div>
        </Menu.Item>
        }

        {authStore.currentUser && isAccessible(constant.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission)) &&
        <Menu.Item key="3"  className={`${isActive(Routes.groups) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink to={Routes.groups}>
              <Icon className="icon-14 mr-2" iconRef="#systems_side" />
              <span className="menu-item">Assets</span>
            </NavLink>
          </div>
        </Menu.Item>
        } 

        {authStore.currentUser && isAccessible(constant.Permissions['SOAP'].map((p: any) => p.permission)) &&
        <Menu.Item key="4"  className={`${isActive(Routes.e2sensordata) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink to={Routes.e2sensordata}>
              <Icon className="icon-14 mr-2" iconRef="#systems_side" />
              <span className="menu-item">E2 Sensor Data</span>
            </NavLink>
          </div>
        </Menu.Item>
        }

        {authStore.currentUser && isAccessible(constant.Permissions['Raw Data'].map((p: any) => p.permission)) &&
        <Menu.Item key="4"  className={`${isActive(Routes.sensorrawdata) ? "ant-menu-item-selected" : ""}`}>
          <div className="collapse-menu-item">
            <NavLink to={Routes.sensorrawdata}>
              <Icon className="icon-14 mr-2" iconRef="#systems_side" />
              <span className="menu-item">Raw Data</span>
            </NavLink>
          </div>
        </Menu.Item>
        }
        
        {(authStore?.currentUser?.access?.includes(org_admin_access) || authStore?.currentUser?.access?.includes(view_roles)  || authStore?.currentUser?.access?.includes(create_edit_delete_roles) || authStore?.currentUser?.access?.includes(super_admin_access)) &&
        <SubMenu className="d-inline dash_icn" key="sub1" icon={ <Icon className="icon-14 mr-2" iconRef="#settings" />} title="Settings">
          
          {authStore?.currentUser?.access?.includes(super_admin_access) &&
          <Menu.Item key="8"  className={`${isActive(Routes.systemconfig) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.systemconfig}>
                {/* <Icon className="icon-14 mr-2" iconRef="#settings" /> */}
                <span className="menu-item">System Configuration</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

          {authStore?.currentUser?.access?.includes(super_admin_access) &&
          <Menu.Item key="9"  className={`${isActive(Routes.pinconfig) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.pinconfig}>
                {/* <Icon className="icon-14 mr-2" iconRef="#settings" /> */}
                <span className="menu-item">Pin Configuration</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

          {authStore?.currentUser?.access?.includes(super_admin_access) &&
          <Menu.Item key="10"  className={`${isActive(Routes.organization) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.organization}>
                {/* <Icon className="icon-14 mr-2" iconRef="#settings" /> */}
                <span className="menu-item">Organization</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

          {(authStore?.currentUser?.access?.includes(org_admin_access) || authStore?.currentUser?.access?.includes(view_roles) || authStore?.currentUser?.access?.includes(create_edit_delete_roles)) &&
          <Menu.Item key="21"  className={`${isActive(Routes.role) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.role}>
                {/* <Icon className="icon-14 mr-2" iconRef="#settings" /> */}
                <span className="menu-item">Roles</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

          {(authStore?.currentUser?.access?.includes(org_admin_access) || authStore?.currentUser?.access?.includes(super_admin_access)) &&
          <Menu.Item key="11"  className={`${isActive(Routes.softsavings) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.softsavings}>
                <span className="menu-item">Soft Savings</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

          {authStore?.currentUser?.access?.includes(super_admin_access) &&
          <Menu.Item key="12"  className={`${isActive(Routes.algorithmconfig) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.algorithmconfig}>
                {/* <Icon className="icon-14 mr-2" iconRef="#settings" /> */}
                <span className="menu-item">Large Chiller Alerts</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

          {authStore?.currentUser?.access?.includes(super_admin_access) &&
          <Menu.Item key="13"  className={`${isActive(Routes.oldRulegenerator) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.oldRulegenerator}>
                {/* <Icon className="icon-14 mr-2" iconRef="#systems_side" /> */}
                <span className="menu-item">Old - Rule Generator</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

          {authStore?.currentUser?.access?.includes(super_admin_access) &&
          <Menu.Item key="13"  className={`${isActive(Routes.rulegenerator) ? "ant-menu-item-selected" : ""}`}>
            <div className="collapse-menu-item">
              <NavLink  to={Routes.rulegenerator}>
                {/* <Icon className="icon-14 mr-2" iconRef="#systems_side" /> */}
                <span className="menu-item">Rule Generator</span>
              </NavLink>
            </div>
          </Menu.Item>
          }

        </SubMenu>
        }

        
      </Menu>
    </Layout.Sider>
  );
};

export default withRouter(Sidebar);
