class RulesJson {
  assetType: string;
  anchors: Record<string, any>;
  rules: any[];

  constructor(assetType: string = '', anchors: Record<string, any> = {}, rules: any[] = []) {
    this.assetType = assetType;
    this.anchors = anchors;
    this.rules = rules;
  }
}

const anchorOperatorMapping: Record<string, string> = {
  '==': 'equal',
  '<=': 'less_or_equal',
  '<': 'less',
  '>': 'greater',
  '>=': 'greater_or_equal',
  '!=': 'not_equal',
  'is': 'equal',
};

const operatorMapping: Record<string, string> = {
  '==': '=',
  '!=': '!=',
  '<': '<',
  '<=': '<=',
  '>': '>',
  '>=': '>=',
  'is': '='
};

const operatorMappingInverted: Record<string, string> = {
  'eq': '==',
  'lte': '<=',
  'lt': '<',
  'gt': '>',
  'gte': '>=',
  'neq': '!=',
  'is': 'is',
};

const logicalOperatorMapping: Record<string, boolean> = {
  AND: true,
  OR: true,
};

const specialKeys: Record<string, boolean> = {
  prerequisite: true,
  trigger_pre: true,
  trigger_post: true,
  email_condition: true,
};

function processCondition(value: any) {
  const s = value.value.split('.');
  const regex = /^(\S+?)(_\d{1,2})?\s*$/;
  const match = s[0].match(regex);
  let seq = 0;

  if (match?.[2]) {
    seq = parseInt(match[2].substring(1));
    s[0] = match[1];
  }

  if (s[2]) {
    s[1] = `${s[1]}.${s[2]}`;
  }

  const operands = s[1].split(' ');
  const triState = ['False', 'True', 'None'];

  let type = 'string';
  let opValue = operands[2].replace(/"/g, '');
  let input = 'string';
  let operator = operatorMapping[operands[1]];

  if (!isNaN(+operands[2])) {
    input = 'number';
    type = 'double';
    opValue = Number(operands[2]);
  } else if (triState.includes(operands[2])) {
    type = 'string';
    input = 'select';
    operator = 'equal';
    opValue = triState[2];
  }

  let valueResult: any = opValue;
  if (s[0] !== '_sys_attr' && s[0] !== '_state') {
    valueResult = {
      value: opValue,
      seq: seq,
    };
  }

  if (s[0] === '_state' && operands[0] === 'in_business_hour') {
    if (valueResult === 2) {
      valueResult = 'none';
    }
  }

  return {
    id: `${s[0]} - ${operands[0]}`,
    field: `${s[0]} - ${operands[0]}`,
    input,
    type,
    operator,
    value: valueResult,
  };
}

function processConditionRecursive(anchors: Record<string, any>, value: any): any {
  let jsonRule: any = {};
  let c = 0;
  for (let i = 0; i < value.items.length; i++) {
    const key = value.items[i].key.value;
    if (logicalOperatorMapping[key]) {
      jsonRule['condition'] = key;
      jsonRule['rules'] = [];

      for (let j = 0; j < value.items[i].value.items.length; j++) {
        const item = value.items[i].value.items[j];

        if ('items' in item) {
          jsonRule['rules'][c] = processConditionRecursive(anchors, item);
          c++;
          continue;
        }

        if ('type' in item) {
          jsonRule['rules'][c] = processCondition(item.source);
          c++;
        } else {
          jsonRule['rules'][c] = anchors[item.source]?.condition;
          c++;
        }
      }
    } else if (key === 'condition') {
      return processConditionRecursive(anchors, value.items[i].value);
    }
  }
  return jsonRule;
}

function yamlToJsonNew(selectedAssetType: string, rules: any) {
  const jsonRules = new RulesJson();

  if ('anchors' in rules) {
    rules.anchors.map.forEach((anchorMap: any, anchorKey: string) => {
      console.log(`anchorName: ${anchorKey}`);
      const jsonAnchor: Record<string, any> = {};

      for (let i = 0; i < anchorMap.items.length; i++) {
        const k = anchorMap.items[i].key;
        const v = anchorMap.items[i].value;
        let opValue = v.value;

        if (k.value === 'condition') {
          opValue = processCondition(opValue);
          opValue['anchor_name'] = anchorKey;
        }
        jsonAnchor[k.value] = opValue;
      }

      jsonRules.anchors[anchorKey] = jsonAnchor;
    });

    for (let i = 0; i < rules.contents.items.length; i++) {
      const key = rules.contents.items[i].key;
      const value = rules.contents.items[i].value;

      if (key.value === '_rules' && value.items[0].key === 'Default') {
        const ruleList = value.items[0].value.items;
        if (ruleList) {
          ruleList.forEach((rule: any, k: number) => {
            const items = rule.items;
            const jsonRule: Record<string, any> = {};

            for(var l = 0; l < items.length; l++ ) {
              if (items[l].key.value in specialKeys) {
                var jr = processConditionRecursive(jsonRules.anchors, items[l].value)
                jsonRule[items[l].key.value] = jr
                continue
              }
              jsonRule[items[l].key] = items[l].value.value
            }

            jsonRules.rules[k] = jsonRule;
          });
        }
      }
    }
  } else {
    for (let i = 0; i < rules.contents.items.length; i++) {
      // console.log("HERE - 001", rules.contents.items[i])
      const key = rules.contents.items[i].key;
      const value = rules.contents.items[i].value;

      if ('anchor' in value) {
        const jsonAnchor: Record<string, any> = {};

        for (let j = 0; j < value.items.length; j++) {
          const k = value.items[j].key;
          const v = value.items[j].value;
          let opValue = v.value;

          if (k === 'condition') {
            opValue = processCondition(v);
            opValue['anchor_name'] = value.anchor;
          }
          jsonAnchor[k] = opValue;
        }
        jsonRules.anchors[value.anchor] = jsonAnchor;
      } else if (key.value == '_rules' && value.items[0].key == 'Default') {
        const ruleList = value.items[0].value.items;
        if (ruleList) {
          ruleList.forEach((rule: any, k: number) => {
            const items = rule.items;
            const jsonRule: Record<string, any> = {};

            for(var l = 0; l < items.length; l++ ) {
              if (items[l].key.value in specialKeys) {
                var jr = processConditionRecursive(jsonRules.anchors, items[l].value)
                jsonRule[items[l].key.value] = jr
                continue
              }
              jsonRule[items[l].key] = items[l].value.value
            }

            jsonRules.rules[k] = jsonRule;
          });
        }
      }
    }
  }

  jsonRules.assetType = selectedAssetType;
  return jsonRules;
}


export default yamlToJsonNew;


interface Rule {
  id: string;
  field: string;
  operator: string;
  sequence: number;
  value: string | number | null;
}

interface QueryBuilderRule {
  // id: string;
  combinator: 'and' | 'or';
  rules: (Rule | QueryBuilderRule)[];
}

const parseRuleString = (ruleString: string): Rule | null => {
  // const regex = /^(.+?)\s*(==|=|!=|<=|<|>=|>|is)\s*(.+)$/;
  const regex = /^([\w.]+)\s*(==|=|!=|<=|<|>=|>|is)\s*(.+)$/;
  const match = ruleString.match(regex);

  if (match) {
    console.log("NEW match: ", match)
    let [id, field, operator, value] = match;
    const fieldRegex = /_(\d+)\./;
    const fieldMatch = field.match(fieldRegex);
    let sequence = 0;
    if (fieldMatch) {
      console.log("NEW FIELD match: ", fieldMatch)
      sequence = Number(fieldMatch[1]);
      field = field.replace('_' + fieldMatch[1], '');
    }
    return {
      id: `${id} - ${field}`,
      field: field.trim(),
      operator: operatorMapping[operator.trim()] || operator.trim(),
      sequence: sequence,
      value: value === 'None' ? 'None' : !isNaN(Number(value)) ? Number(value) : value.replace(/^"(.*)"$/, '$1').trim(),
    };
  }

  return null;
};

export function convertToQueryBuilderFormat(data: any): QueryBuilderRule {
  const root: QueryBuilderRule = {
    combinator: data.condition.toLowerCase() as 'and' | 'or',
    rules: [],
  };

  data.rules.forEach((rule: any) => {
    if (typeof rule === 'string') {
      const parsedRule = parseRuleString(rule);
      // console.log("parsedRule", parsedRule)
      if (parsedRule) {
        root.rules.push(parsedRule);
      }
    } else if (rule.condition) {
      root.rules.push(convertToQueryBuilderFormat(rule));
    }
  });

  return root;
};


export function checkAndAddAnchorsCond(anchors: Record<string, any>, cond: any): void {
  const regex = /^(\S+)\s*-\s*(\S+)$/;
  const matchr = cond.id?.match(regex);
  let stateName: string | undefined;
  let condName: string | undefined;
  let anchorName: string | undefined;
  let seq = 0;

  if (matchr) {
    stateName = matchr[1];
    condName = matchr[2];
    const op = Object.keys(anchorOperatorMapping).find((k) => anchorOperatorMapping[k] === cond['operator']);
    let val = cond.value;

    if (typeof val === 'object') {
      if (val.seq !== '0' && val.seq !== undefined) {
        seq = val.seq;
      }
      val = val.value;
      if (val === 'None') {
        val = 'none';
      }
    }

    const regexDec = /^(\d+)\.(\d+)$/;
    const matchDec = val.toString().match(regexDec);
    if (matchDec) {
      val = `${matchDec[1]}_pt_${matchDec[2]}`;
    }

    if (stateName === '_sys_attr') {
      anchorName = `system_${condName}_${op}_${val}`;
    } else if (stateName === '_state') {
      if (condName === 'in_business_hour') {
        if (val === 2) {
          val = 'none';
        }
      }
      anchorName = `status_${condName}_${op}_${val}`;
    } else {
      if (seq !== 0 && seq !== undefined) {
        anchorName = `${stateName}_${seq}_${condName}_${op}_${val}`;
      } else {
        anchorName = `${stateName}_${condName}_${op}_${val}`;
      }
    }

    if (anchorName in anchors) {
      console.log('Anchor exists, adding alias');
      cond = { ...cond, anchor_name: anchorName };
    } else {
      const anchorCond: Record<string, any> = {
        condition: `${cond['field']} ${cond['operator']} ${val}`,
        description: `${cond['field']} ${cond['operator']} ${val}`,
      };

      anchors[anchorName] = anchorCond;
      cond = { ...cond, anchor_name: anchorName };
    }
  }
}

export function formatRules(ruleGroup: any): any {
  return {
    condition: ruleGroup.combinator.toUpperCase(), // Convert combinator to uppercase
    rules: ruleGroup.rules.map((rule: any) => {
      if (rule.rules) {
        // Handle nested rules recursively
        return formatRules(rule);
      } else {
        // Format individual rule into string
        return `${rule.field} ${rule.operator} ${rule.value}`;
      }
    })
  };
};

export function checkAndAddAnchorsRule(anchors: any[], rule: any): void {
  if (rule.combinator) {
      if (rule.rules) {
        rule.rules.forEach((cond: any) => {
          checkAndAddAnchorsRule(anchors, cond);
        });
      }
    } else {
      if (rule.id) {
        checkAndAddAnchorsCond(anchors, rule);
      }
    }
  }